<template>
  <div class="main_box">
    <section id="section1" class="section_box">
      <div class="section1_box">
        <div :class="[`sections1_left`, { active: isVisible == 0 }]">
          <div class="text_box">
            <h1 class="head_name">{{ CorporateTitle }}</h1>
            <div class="icon_origin"></div>
            <div class="translate_lefts1">
              {{ CorporateConent }}
            </div>
          </div>
        </div>
        <div class="sections1_right1">
          <el-image
            v-for="item in list"
            :key="item.id"
            :src="item.images"
            class="icon_origin1"
            :class="[`icon_origin${item.id}`, { active: isVisible == 0 }]"
          ></el-image>
        </div>
      </div>
    </section>
    <section id="section2" class="section_box">
      <div class="section1_box">
        <div :class="[`section1_left1`, { active: isVisible == 1 }]">
          <div class="title_box">
            <h1 class="head_name">{{ PersonalInformationTitle }}</h1>
            <span class="title_span">{{ PersonalInformationConent }}</span>
          </div>
          <div class="icon_origin"></div>
          <div class="translate_left2">
            {{ PersonalInformationText }}
          </div>
        </div>
        <div class="section1_right2">
          <div :class="[`img1class_box1`, { active: isVisible == 1 }]">
            <el-image class="img1class1" :src="img1"></el-image>
          </div>
          <div :class="[`img1class_box2`, { active: isVisible == 1 }]">
            <el-image class="img2class1" :src="img2"></el-image>
            <el-image class="img2class2" :src="img3"></el-image>
          </div>
        </div>
      </div>
    </section>
    <section id="section3" class="section_box">
      <div class="section1_box1">
        <div
          class="scroll-container"
          @mouseenter="pauseScroll"
          @mouseleave="resumeScroll"
        >
          <div class="scroll-content" :style="scrollStyle">
            <div
              v-for="(item, index) in scrollList"
              :key="index"
              class="scroll-item"
            >
              <div class="conent_box1">
                <h6 class="conent_h1">{{ item.title }}</h6>
                <div class="conent_time">{{ item.time }}</div>
                <div v-if="item.isTrue1" class="icon_origin"></div>
              </div>
              <!-- <hr> -->
              <div class="conent_box2">
                <h6 class="conent_h1">{{ item.title1 }}</h6>
                <div class="conent_time">{{ item.time1 }}</div>
                <div v-if="item.isTrue2" class="icon_origin"></div>
              </div>
            </div>
            <!-- 复制一份内容以实现无缝滚动 -->
            <div
              v-for="(item, index) in scrollList"
              :key="'copy-' + index"
              class="scroll-item"
            >
            <div class="conent_box1">
                <h6 class="conent_h1">{{ item.title }}</h6>
                <div class="conent_time">{{ item.time }}</div>
                <div v-if="item.isTrue1" class="icon_origin"></div>
              </div>
              <!-- <hr> -->
              <div class="conent_box2">
                <h6 class="conent_h1">{{ item.title }}</h6>
                <div class="conent_time">{{ item.time }}</div>
                <div v-if="item.isTrue2" class="icon_origin"></div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { CorporateName, PersonalInformation, ProductInformation} from "@/https/api.js";
export default {
  name: "Brief",
  data() {
    return {
      isVisible: 0,
      isPaused: false, // 是否暂停滚动
      CorporateTitle: "",
      CorporateConent: "",
      PersonalInformationTitle: "",
      PersonalInformationConent: "",
      PersonalInformationText: "",
      img1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/boss_hu_shen.png",
      img2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/boss_hu_face.png",
      img3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/boss_gao_face.png",
      list: [],
      scrollList: [],
    };
  },
  computed: {
    scrollStyle() {
      return {
        animationPlayState: this.isPaused ? 'paused' : 'running',
      };
    },
  },
  mounted() {
    this.CorporateNameFun();
    this.PersonalInformationFun();
    this.ProductInformationFun();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Array.from(entry.target.parentNode.children).indexOf(
              entry.target
            );
            this.isVisible = index;
            // this.contentList[index].isVisible = true;
            // 停止观察已触发的模块
            // observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.2, // 交叉比例达到50%时触发回调
      }
    );

    const moduleElement = document.querySelectorAll(".section_box");
    moduleElement.forEach((module) => {
      observer.observe(module);
    });
  },
  methods: {
    ProductInformationFun() {
      ProductInformation().then((res) => {
        let data = res.data;
        this.scrollList = data;
      })
    },
    PersonalInformationFun() {
      PersonalInformation().then((res) => {
        let data = res.data;
        this.PersonalInformationTitle = data.title;
        this.PersonalInformationConent =  data.conent;
        this.PersonalInformationText = data.text;
        this.img1 = data.img1;
        this.img2 = data.img2;
        this.img3 = data.img3;
      }).catch((err) => {
        return err;
      })
    },
    CorporateNameFun() {
      CorporateName().then((res) => {
        let data = res.data;
        this.CorporateTitle = data.title;
        this.CorporateConent = data.conent;
        this.list = data.imageList;
      }).catch(err=>{
        return err;
      })
    },
     // 鼠标划入时暂停滚动
     pauseScroll() {
      this.isPaused = true;
    },
    // 鼠标划出时恢复滚动
    resumeScroll() {
      this.isPaused = false;
    },
  },

};
</script>
<style scoped lang="less">
@import url("./index.css");
/* 全局样式 */
</style>