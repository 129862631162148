import { post } from './axios.js';

// 业务部分
export function business(params={}){
    return post('/api/business', params);
}

// 简介部分
export function BriefIntroduction(params={}){
    return post('/api/BriefIntroduction', params);
}

// 新闻部分
export function News(params={}){
    return post('/api/News', params);
}

// 新闻部分
export function News1(params={}){
    return post('/api/News1', params);
}

// 福利部分
export function Benefit(params={}){
    return post('/api/Benefit', params);
}

// 加入我们
export function JoinUs(params={}){
    return post('/api/JoinUs', params);
}

// 联系我们
export function ContactUs(params={}){
    return post('/api/ContactUs', params);
}

// 业务合作
export function BusinessCooperation(params={}){
    return post('/api/BusinessCooperation', params);
}

// SDK业务
export function SDKBusiness(params={}){
    return post('/api/SDKBusiness', params);
}

// 厦门风铃鸟信息科技有限公司
export function CorporateName(params={}){
    return post('/api/CorporateName', params);
}

// 个人信息
export function PersonalInformation(params={}){
    return post('/api/PersonalInformation', params);
}

// 产品信息
export function ProductInformation(params={}){
    return post('/api/ProductInformation', params);
}

// 九顿生活
export function JiuDunLife(params={}){
    return post('/api/JiuDunLife', params);
}