<template>
  <div class="main_box">
    <section class="section_box">
      <div class="conent2_box">
        <div :class="[`section2_left_box`, {active: isVisible==0}]">
          <h1 class="head_name">{{ JoinTitle }}</h1>
          <div class="icon_origin"></div>
        </div>
        <div :class="[`section2_right_box`, {active: isVisible==0}]">
          <div class="image_box" v-for="(item,index) in JoinList" :key="index">
            <el-image class="img" :src="item.url"></el-image>
            <div class="tit">{{ item.name }}</div>
            <el-button class="btn" type="warning" plain>{{ item.btnCon }}</el-button>
          </div>
        </div>
      </div>
    </section>
    <section class="section_box">
      <div class="conent1_box">
        <div :class="[`section1_left_box`, {active: isVisible==1}]">
          <h1 class="head_name">{{ BenefitTitle }}</h1>
          <div class="icon_origin"></div>
        </div>
        <div class="section1_right_box">
          <div
            v-for="item in BenefitList"
            :key="item.index"
            :style="{ background: backgrFundColor(item.index) }"
            :class="[`conent_title${item.index}`, {active: isVisible==1}]"
          >
            <div class="num">{{ item.num }}</div>
            <div class="title">{{ item.title }}</div>
            <div class="cont">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </section>

    <section class="section_box">
      <div class="conent_box">
        <div class="section1_top">
          <div :class="[`title_text`, {active: isVisible==2}]">
            <h1 class="head_name">{{ ContactUsTitle }}</h1>
            <div class="icon_origin"></div>
          </div>
          <div :class="[`translate_righe`, {active: isVisible==2}]">
            <div class="translate_righe_text">
              {{ ContactUsAddress }}
            </div>
            <div class="translate_righe_text">
              {{ ContactUsPhone }}
            </div>
            <div class="translate_righe_text">{{ ContactUsEmail }}</div>
          </div>
        </div>
        <div
          :class="[`section1_bottom`, {active: isVisible==2}]"
          @mouseenter="pauseScroll"
          @mouseleave="resumeScroll"
        >
          <baidu-map
            id="map"
            class="map1"
            :center="centerName"
            :scroll-wheel-zoom="true"
            :zoom="zooms"
            @ready="ready"
          >
          </baidu-map>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { JoinUs, Benefit, ContactUs } from "@/https/api.js";
export default {
  name: "App",
  data() {
    return {
      centerName: { lng: 116.404, lat: 39.915 },
      zooms: 15,
      isScrollPaused: false,
      isVisible: 0,
      JoinTitle: "",
      JoinList: [],
      BenefitTitle: "",
      BenefitList: [],
      ContactUsTitle: "",
      ContactUsEmail: "",
      ContactUsPhone: "",
      ContactUsAddress: ""
    };
  },
  components: {},
  mounted() {
    this.JoinUsFun();
    this.BenefitFun();
    this.ContactUsFun();
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Array.from(entry.target.parentNode.children).indexOf(entry.target);
            this.isVisible = index;
            // this.contentList[index].isVisible = true;
            // 停止观察已触发的模块
            // observer.unobserve(entry.target);
        }
      });
    },{
      threshold: 0.2, // 交叉比例达到50%时触发回调
    });
    
    const moduleElement = document.querySelectorAll(".section_box");
    moduleElement.forEach((module) => {
      observer.observe(module);
    })
  },
  created() {},
  methods: {
    ContactUsFun() {
      ContactUs().then(res=>{
        let data = res.data;
        this.ContactUsTitle = data.title;
        this.ContactUsEmail = data.Email;
        this.ContactUsPhone = data.Phone;
        this.ContactUsAddress = data.Address;
      }).catch(err=>{
        return err;
      })
    },
    BenefitFun() {
      Benefit().then(res=>{
        let data = res.data;
        this.BenefitTitle = data.title;
        this.BenefitList = data.contentList;
      }).catch(err=>{
        return err;
      })
    },
    JoinUsFun() {
      JoinUs().then((res) => {
        let data = res.data;
        this.JoinTitle = data.title;
        this.JoinList = data.JoinList;
      }).catch((err) => {
        return err;
      })
    },
    ready({ BMap, map }) {
      console.log(BMap, map);
    },
    backgrFundColor(index) {
      if (index < 4) {
        if (index % 2 == 0) {
          return "#fff";
        } else {
          return "hsla(0, 0%, 85%, .1)";
        }
      } else {
        if (index % 2 == 0) {
          return "hsla(0, 0%, 85%, .1)";
        } else {
          return "#fff";
        }
      }
    },
    pauseScroll() {
      const mapContainer = document.querySelector(".section1_bottom");
      mapContainer.addEventListener(
        "wheel",
        (event) => {
          event.stopPropagation(); // 阻止事件冒泡
          event.preventDefault(); // 阻止默认滚动行为
        },
        { passive: true }
      );
    },
    resumeScroll() {
      if (this.isScrollPaused) {
        this.isScrollPaused = false;
      }
    },
    preventScroll(event) {
      event.preventDefault(); // 阻止默认滚动行为
    },
  },
};
</script>

<style scoped lang="less">
@import url("./index.css");
::v-deep .BMap_bubble_title {
  font-size: 14px;
  font-weight: bold;
  color: #d07852;
}

::v-deep .BMap_cpyCtrl {
  display: none;
}

::v-deep .anchorBL {
  display: none;
}
</style>