import Mock from "mockjs";
// 业务部分
Mock.mock("/api/business", "post", {
  'status': 200,
  message: "请求成功",
  'data': {
    title: "公司业务",
    list: [
      {
        name: "1",
        url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/tianqiyubao_logo.png",
        title: "视频特效大师",
        conent:
          "视频特效大师一款集AI类制作、数字人、一键成片等一站式制作的APP。将人工智能的强大与创意玩法集为一体给您带来前所未有的视频创作体验！",
        url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_3.png",
        url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_2.png",
        url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_4.png",
      },
      {
        name: "2",
        url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/kexiu_logo.png",
        title: "一键换脸",
        conent:
          "一键换脸是一款全新的AI智能换脸app，只需要上传1张照片，就可以通过人脸融合技术一键合成古风汉服、青春风华、高冷女神、肌肉型男等热门装扮视频动态效果!",
        url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_3.png",
        url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_2.png",
        url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_4.png",
      }
    ],
  }
});
// 简介部分
Mock.mock('/api/BriefIntroduction', 'post', () => {
  return {
    code: 200,
    message: '提交成功',
    data: {
      title: '公司简介',
      CompanyName: "厦门风铃鸟信息科技有限公司",
      conent1: "成立于2013年5月23日，从重庆大学计算机实验室里的三人团队，发展成为今天拥有350余人的成熟团队。",
      conent2: '九吨科技秉承着"务实、创新、担当、热爱"的创业理念，打造了一家年轻有活力的科技型创新企业，团队成员平均年龄26岁，一直专注于移动互联网产品的自主研发和运营，在移动App矩阵、移动广告平台两大业务板块中，拥有四十余款核心产品，累计用户超过六亿，月活跃(MAU)用户超过两千万，是西南地区为数不多的用户量突破六亿大关的移动互联网公司。2017年成立了全资子公司，重庆赋比兴科技有限公司，后将九吨科技的业务全部转移至子公司中。',
      carouseList: [
        {
          url: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-06-07/tourism.jpg",
          id: 1,
        },
        {
          url: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-02-29/7a14cc1683e6accb636ac026071a17c7.jpg",
          id: 2,
        },
        {
          url: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-01-30/2701cdf2fdc93d6d3f3dc567760daf4f.jpg",
          id: 3,
        },
        {
          url: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-10-31/170ccf4e56964fc6e7986196cc92c94f.jpg",
          id: 4,
        },
        {
          url: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/2019team_video_toutu.jpeg",
          id: 5,
        },
      ],
    },
  };
});
// 新闻部分
Mock.mock('/api/News', 'post', () => {
  return {
    code: 200,
    message: '提交成功',
    data: {
      title: '九顿新闻',
      scrollList: [{
        id: 1,
        title: "九吨科技成立，九吨科技成立",
        time: "2020-05",
        isTrue2: true, // 是否显示
        title1: "懒猫试玩App上线",
        time1: "2020-10",
        isTrue1: true,
        url1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        url2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
      },
      {
        id: 2,
        title: "赋比兴科技当选“2022年度重庆市重点软件和信息服务-成长型企业”",
        time: "2020-10",
        isTrue2: true,
        title1: "开启“九吨红人榜”评选活动",
        time1: "2020-10",
        isTrue1: true,
        url1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        url2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
      },
      {
        id: 3,
        title: "赋比兴科技入选“两江新区2023年度软件和信息服务企业综合竞争力20强",
        time: "2020-10",
        isTrue1: false,
        isTrue2: true,
        title1: "九吨原创IP形象“吨总”诞生",
        time1: "2020-10",
        url1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        url2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
      },
      {
        id: 4,
        title: "九吨全资子公司（重庆赋比兴科技有限公司）成立",
        time: "2020-10",
        isTrue1: true,
        title1: "2022年9月 九吨新加坡公司（NINETON PTE. LTD.）成立",
        time1: "2020-10",
        isTrue2: true,
        url1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        url2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
      }],
    },
  };
});
// 新闻部分111
Mock.mock('/api/News1', 'post', () => {
  return {
    code: 200,
    message: '提交成功',
    data: {
      title: '九顿新闻',
      scrollList: [{
        id: 1,
        image: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        title: "11月生日会",
        description: "新的一岁每一天都和蛋糕一样甜蜜~",
        image1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        title1: "11月生日会",
        description1: "新的一岁每一天都和蛋糕一样甜蜜~",
        image2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        title2: "11月生日会",
        description2: "新的一岁每一天都和蛋糕一样甜蜜~",
      },
      {
        id: 2,
        image: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        title: "1024程序员节",
        description: "带你躺平~",
        image1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        title1: "1024程序员节",
        description1: "带你躺平~",
        image2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        title2: "1024程序员节",
        description2: "带你躺平~",
      },
      {
        id: 3,
        image: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        title: "11月生日会",
        description: "新的一岁每一天都和蛋糕一样甜蜜~",
        image1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        title1: "11月生日会",
        description1: "新的一岁每一天都和蛋糕一样甜蜜~",
        image2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        title2: "11月生日会",
        description2: "新的一岁每一天都和蛋糕一样甜蜜~",
      },]
    },
  };
});
// 福利部分
Mock.mock('/api/Benefit', 'post', () => {
  return {
    code: 200,
    message: '提交成功',
    data: {
      title: '九顿福利',
      contentList: [{
        num: null,
        title: "",
        content: "",
        index: 0,
        isVisible: false,
      },
      {
        num: "01",
        title: "薪酬待遇",
        content: "同行业有竞争的薪酬，五险一金，丰厚的年终奖",
        index: 1,
        isVisible: false,
      },
      {
        num: "02",
        title: "工作时间",
        content: "周末双休，弹性上下班时间，带薪年假",
        index: 2,
        isVisible: false,
      },
      {
        num: "03",
        title: "餐饮零食",
        content: "午餐补贴，每日不同零食供应，每月豪华下午茶",
        index: 3,
        isVisible: false,
      },
      {
        num: "04",
        title: "员工福利",
        content: "生日福利，节日福利，团建活动，员工旅游",
        index: 4,
        isVisible: false,
      },
      {
        num: "05",
        title: "晋升空间",
        content: "完善的晋升体系，优秀人才优先考虑晋升机会",
        index: 5,
        isVisible: false,
      },
      {
        num: "06",
        title: "办公环境",
        content: "舒适宽敞的办公环境，配备齐全的办公设施",
        index: 6,
        isVisible: false,
      },
      {
        num: "07",
        title: "培训学习",
        content: "丰富的培训课程，提升专业技能，拓展视野",
        index: 7,
        isVisible: false,
      },]
    },
  };
});
// 加入我们
Mock.mock("/api/JoinUs", "post", () => {
  return {
    code: 200,
    data: {
      title: "加入九吨",
      JoinList: [{
        url: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/job01.png",
        name: "高级UI设计师",
        btnCon: "查看职务",
      },{
        url: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/job02.png",
        name: "安卓工程师",
        btnCon: "查看职务",
      },{
        url: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/job03.png",
        name: "高级产品经理",
        btnCon: "查看职务",
      }]
    }
  }
})
// 联系我们
Mock.mock("/api/ContactUs", "post", () => {
  return {
    code: 200,
    data: {
      title: "联系我们",
      Address: "地址：重庆市渝北区洪湖西路24号B幢11、12F",
      Phone: "电话：023-67463753；17300280378",
      Email: "邮箱：hr@nineton.cn",
    }
  }
});

// 业务合作
Mock.mock("/api/BusinessCooperation", "post", () => {
  return {
    code: 200,
    data: {
      title: "业务合作",
      AppAdvertisement: "移动APP广告合作",
      Name: "姓名：王娇",
      Email: "邮箱：wangjiao@nineton.cn",
      url: "https://nineton.cn/img/pic_23.4ec0185a.png",
    }
  }
});

// SDK业务
Mock.mock("/api/SDKBusiness", "post", () => {
  return {
    code: 200,
    data: {
      title: "SDK业务",
      sdkList:[{
        version: "3.6.8 广告SDK 版本",
        info: [{
          label: "SDK名称：",
          value: "九吨Android广告SDK",
        },{
          label: "SDK所属企业名称：",
          value: "厦门风铃鸟信息科技有限公司",
        },{
          label: "SDK说明：",
          value: "用于广告投放、广告监测、广告归因、广告效果优化",
        }],
        links:[
          {
            name: "接入文档",
            url1: "https://nineton.cn/advertising.html",
          },
          {
            name: "影规政策",
            url1: "https://nineton.cn/advertising.html",
          },
          {
            name: "合规使用文档",
            url1: "https://nineton.cn/advertising.html",
          },
        ]
      },{
        version: "3.6.8 广告SDK 版本",
        info: [{
          label: "SDK名称：",
          value: "九吨Android广告SDK",
        },{
          label: "SDK所属企业名称：",
          value: "厦门风铃鸟信息科技有限公司",
        },{
          label: "SDK说明：",
          value: "用于广告投放、广告监测、广告归因、广告效果优化",
        }],
        links:[
          {
            name: "接入文档",
            url1: "https://nineton.cn/advertising.html",
          },
          {
            name: "影规政策",
            url1: "https://nineton.cn/advertising.html",
          },
          {
            name: "合规使用文档",
            url1: "https://nineton.cn/advertising.html",
          },
        ]
      }],
    }
  }
});
// 厦门风铃鸟信息科技有限公司
Mock.mock('/api/CorporateName', 'post', () => {
  return {
    code: 200,
    data: {
      title: "厦门风铃鸟信息科技有限公司",
      conent: "九吨科技秉承着“务实、创新、担当、热爱”的创业理念，打造了一家年轻有活力的科技型创新企业。",
      imageList: [
        {
          id: 1,
          images:
            "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-08-09/d643985fb355661564dc1587a27fdfa5.png",
        },
        {
          id: 2,
          images:
            "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-08-09/6a0d4832ecf5df8da725081c498f0feb.png",
        },
        {
          id: 3,
          images:
            "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-08-09/b6b87fcd5c7308b08f7802057eec41c3.png",
        },
        {
          id: 4,
          images:
            "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-08-09/306bf2b54e42e1794163f549d0c43049.png",
        },
      ],
    }
  }
})
// 个人信息
Mock.mock('/api/PersonalInformation', 'post', () => {
  return {
    code: 200,
    data: {
      title: "胡建安",
      conent: "九吨科技董事长/重庆大学硕士",
      text: "作为九吨科技创始人，将早年的3人创业团队通过十年来的运作，发展到今天拥有350余名员工的规模，其在团队管理、人员激励等有着独到建树，更是对app开发、推广、运营都有很丰富的实战经验，公司开发的包括新晴天气、手写输入法等在内优秀互联网产品。",
      img1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/boss_hu_shen.png",
      img2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/boss_hu_face.png",
      img3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/boss_gao_face.png",
    }
  }
})
// 产品信息
Mock.mock('/api/ProductInformation', 'post', () => {
  return {
    code: 200,
    data: [
      {
        id: 1,
        title: "九吨科技成立，九吨科技成立",
        time: "2020-05",
        isTrue2: true, // 是否显示
        title1: "懒猫试玩App上线",
        time1: "2020-10",
        isTrue1: true,
      },
      {
        id: 2,
        title: "赋比兴科技当选“2022年度重庆市重点软件和信息服务-成长型企业”",
        time: "2020-10",
        isTrue2: true,
        title1: "开启“九吨红人榜”评选活动",
        time1: "2020-10",
        isTrue1: true,
      },
      {
        id: 3,
        title: "赋比兴科技入选“两江新区2023年度软件和信息服务企业综合竞争力20强",
        time: "2020-10",
        isTrue1: false,
        isTrue2: true,
        title1: "九吨原创IP形象“吨总”诞生",
        time1: "2020-10",
      },
      {
        id: 4,
        title: "九吨全资子公司（重庆赋比兴科技有限公司）成立",
        time: "2020-10",
        isTrue1: true,
        title1: "2022年9月 九吨新加坡公司（NINETON PTE. LTD.）成立",
        time1: "2020-10",
        isTrue2: true,

      }
    ],
  }
})

// 九顿生活
Mock.mock('/api/JiuDunLife', 'post', () => {
  return {
    code: 200,
    data: {
      title: "九吨生活",
      img: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-07-31/02b262814095598c05242a9eb703e06d.png",
      img1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/life2.png",
      img2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/life3.png",
      img3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/life4.png",
      img4: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-07-31/a803cff30958365cdd381d1ab7a457e8.png",
      img5: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/life6.png",
    }
  }
})