import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home/index.vue';
import Business from '@/views/Business/index.vue';
import About from '@/views/About/index.vue';
import Brief from '@/views/Brief/index.vue';
import Contact from '@/views/Contact/index.vue';
import Culture from '@/views/Culture/index.vue';
Vue.use(Router);


export default new Router({
  mode: 'hash', // 使用 HTML5 历史模式
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: '/Business',
      children: [
        {
          path: '/Business',
          name: 'Business',
          id: 1,
          component: Business,
        },
        {
          path: '/About',
          name: 'About',
          id: 3,
          component: About,
        },
        {
          path: '/Brief',
          name: 'Brief',
          id: 3,
          component: Brief,
        },
        {
          path: '/Contact',
          name: 'Contact',
          id: 4,
          component: Contact,
        },
        {
          path: '/Culture',
          name: 'Culture',
          id: 5,
          component: Culture,
        },]
    },
    // 可以继续添加更多的路由规则
  ],
});