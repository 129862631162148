<template>
  <div class="main_box">
    <section id="section1" class="section_boxs">
      <div class="section_image_box">
        <!-- <el-image :src="img1"></el-image> -->
      </div>
    </section>
    <section id="section2" class="section_box">
      <div class="section4_box">
        <div class="section4_left">
          <div class="text_box"> 
            <h1 class="head_name">{{ businessTitle }}</h1>
            <div class="icon_origin"></div>
            <div :class="[`translate_left4`, { active: isVisible1 == 1 }]">
              <el-image
                class="img_box"
                :src="items[currentIndex].url1"
              ></el-image>
              <h3 class="head_h3">{{ items[currentIndex].title }}</h3>
              <div class="conent_text">
                {{ items[currentIndex].conent }}
              </div>
              <button
                class="btn"
                @mouseenter="pauseScrollFun"
                @mouseleave="resumeScrollFun"
                :style="{
                  backgroundColor: this.isActive ? '#fff' : '#E6A23C',
                  color: this.isActive ? '#000' : '#fff',
                }"
              >
                IOS
              </button>
            </div>
          </div>
        </div>
        <div :class="[`section4_right1`, { active: isVisible1 == 1 }]">
          <el-image
            class="section4_right2_img"
            :src="items[currentIndex].url3"
          ></el-image>
          <el-image
            class="section4_right1_img"
            :src="items[currentIndex].url2"
          ></el-image>
          <el-image
            class="section4_right3_img"
            :src="items[currentIndex].url4"
          ></el-image>
        </div>
      </div>
    </section>
    <section id="section3" class="section_box">
      <div class="section1_box">
        <div :class="[`Bussiness1_left`, { active: isVisible == 2 }]">
          <h1 class="head_name">{{ BriefIntroductionTitle }}</h1>
          <div class="icon_origin"></div>
          <div class="translate_left">
            <h1 class="Bussiness1_title">{{ CompanyName }}</h1>
            <div class="Bussiness1_content">
              {{ conent1 }}
            </div>
            <div class="Bussiness1_text">
              {{ conent2 }}
            </div>
          </div>
        </div>
        <div class="Bussiness1_right">
          <el-carousel indicator-position="outside">
            <el-carousel-item v-for="item in imageList" :key="item.id">
              <el-image :src="item.url"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </section>
    <section id="section4" class="section_box">
      <div class="section1_box">
        <div :class="[`Bussiness2_left`, { active: isVisible == 3 }]">
          <h1 class="head_name">{{ NwesTitle }}</h1>
          <div class="icon_origin"></div>
          <!-- <div class="translate_left">
          </div> -->
        </div>
        <div :class="[`Bussiness2_right`, { active: isVisible == 3 }]">
          <div
            class="scroll-container"
            @mouseenter="pauseScroll"
            @mouseleave="resumeScroll"
          >
            <div class="scroll-content" :style="scrollStyle">
              <div
               v-for="item in scrollList"
               :key="item.id"
                class="scroll-items"
              >
                <div class="conent_boxs1">
                  <el-image :src="item.url1" class="conent_img"></el-image>
                  <div class="conent_box_title">
                    <h6 class="conent_h1_box">{{ item.title }}</h6>
                    <div class="conent_time">{{ item.time }}</div>
                  </div>
                </div>
                <div class="conent_boxs2">
                  <el-image :src="item.url2" class="conent_img"></el-image>
                  <div class="conent_box_title">
                    <h6 class="conent_h1_box">{{ item.title1 }}</h6>
                    <div class="conent_time">{{ item.time1}}</div>
                  </div>
                </div>
              </div>
              
              
              <!-- 复制一份内容以实现无缝滚动 -->
              <div
                v-for="(item, index) in scrollList"
                :key="'copy-' + index"
                class="scroll-items"
              >
              <div class="conent_boxs1">
                  <el-image :src="item.url1" class="conent_img"></el-image>
                  <div class="conent_box_title">
                    <h6 class="conent_h1_box">{{ item.title }}</h6>
                    <div class="conent_time">{{ item.time }}</div>
                  </div>
                </div>
                <div class="conent_boxs2">
                  <el-image :src="item.url2" class="conent_img"></el-image>
                  <div class="conent_box_title">
                    <h6 class="conent_h1_box">{{ item.title1 }}</h6>
                    <div class="conent_time">{{ item.time1}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </section>
    <section id="section5" class="section_box">
      <div class="conent1_box">
        <div :class="[`section1_left_box`, {active: isVisible==4}]">
          <h1 class="head_name">{{ BenefitTitle }}</h1>
          <div class="icon_origin"></div>
        </div>
        <div class="section1_right_box">
          <div
            v-for="item in contentList"
            :key="item.index"
            :style="{ background: backgrFundColor(item.index) }"
            :class="[`conent_title${item.index}`, {active: isVisible==4}]"
          >
            <div class="num">{{ item.num }}</div>
            <div class="title">{{ item.title }}</div>
            <div class="cont">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </section>
    <section class="section_box"  id="section6">
      <div class="conent2_box">
        <div :class="[`section2_left_box`, {active: isVisible==5}]">
          <h1 class="head_name">{{ JoinUsTitle }}</h1>
          <div class="icon_origin"></div>
        </div>
        <div :class="[`section2_right_box`, {active: isVisible==5}]">
          <div class="image_box" v-for="(item,index) in JoinUsList" :key="index">
            <el-image class="img" src="https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/job01.png"></el-image>
            <div class="tit">{{ item.name }}</div>
            <el-button class="btn" type="warning" plain>{{ item.btnCon }}</el-button>
          </div>
        </div>
      </div>
    </section>
    <section id="section7" class="section_box">
      <div class="conent_box">
        <div class="section1_top">
          <div :class="[`title_text`, {active: isVisible==6}]">
            <h1 class="head_name">{{ ContactUsTitle }}</h1>
            <div class="icon_origin"></div>
          </div>
          <div :class="[`translate_righe`, {active: isVisible==6}]">
            <div class="translate_righe_text">
              {{ ContactUsAddress }}
            </div>
            <div class="translate_righe_text">
              {{ ContactUsPhone }}
            </div>
            <div class="translate_righe_text">{{ ContactUsEmail }}</div>
          </div>
        </div>
        <div
          :class="[`section1_bottom`, {active: isVisible==6}]"
          @mouseenter="pauseScroll"
          @mouseleave="resumeScroll"
        >
          <baidu-map
            class="map1"
            :center="centerName"
            :scroll-wheel-zoom="true"
            :zoom="zooms"
            @ready="ready"
          >
          </baidu-map>
        </div>
      </div>
    </section>
  </div>
</template>
  
  <script>
  import { business, BriefIntroduction, News, Benefit, JoinUs, ContactUs } from "@/https/api.js";
export default {
  name: "App",
  data() {
    return {
      businessTitle: "",
      BriefIntroductionTitle: "",
      CompanyName: "",
      conent1: "",
      conent2: "",
      NwesTitle: "",
      BenefitTitle: "",
      JoinUsTitle: "",
      JoinUsList: [],
      ContactUsTitle: "",
      ContactUsAddress: "",
      ContactUsPhone: "",
      ContactUsEmail: "",
      centerName: { lng: 116.404, lat: 39.915 },
      zooms: 15,
      isVisible: 1,
      isVisible1: 1,
      currentIndex: 0,
      isActive: true,
      isPaused: false,
      // img1: "",
      img1: require("../../assets/image/30.jpg"),
      contentList: [
        // {
        //   num: null,
        //   title: "",
        //   content: "",
        //   index: 0,
        //   isVisible: false,
        // },
        // {
        //   num: "01",
        //   title: "薪酬待遇",
        //   content: "同行业有竞争的薪酬，五险一金，丰厚的年终奖",
        //   index: 1,
        //   isVisible: false,
        // },
        // {
        //   num: "02",
        //   title: "工作时间",
        //   content: "周末双休，弹性上下班时间，带薪年假",
        //   index: 2,
        //   isVisible: false,
        // },
        // {
        //   num: "03",
        //   title: "餐饮零食",
        //   content: "午餐补贴，每日不同零食供应，每月豪华下午茶",
        //   index: 3,
        //   isVisible: false,
        // },
        // {
        //   num: "04",
        //   title: "员工福利",
        //   content: "生日福利，节日福利，团建活动，员工旅游",
        //   index: 4,
        //   isVisible: false,
        // },
        // {
        //   num: "05",
        //   title: "晋升空间",
        //   content: "完善的晋升体系，优秀人才优先考虑晋升机会",
        //   index: 5,
        //   isVisible: false,
        // },
        // {
        //   num: "06",
        //   title: "办公环境",
        //   content: "舒适宽敞的办公环境，配备齐全的办公设施",
        //   index: 6,
        //   isVisible: false,
        // },
        // {
        //   num: "07",
        //   title: "培训学习",
        //   content: "丰富的培训课程，提升专业技能，拓展视野",
        //   index: 7,
        //   isVisible: false,
        // },
      ],
      scrollList: [
        // {
        //   id: 1,
        //   title: "九吨科技成立，九吨科技成立",
        //   time: "2020-05",
        //   isTrue2: true, // 是否显示
        //   title1: "懒猫试玩App上线",
        //   time1: "2020-10",
        //   isTrue1: true,
        //   url1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        //   url2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        // },
        // {
        //   id: 2,
        //   title: "赋比兴科技当选“2022年度重庆市重点软件和信息服务-成长型企业”",
        //   time: "2020-10",
        //   isTrue2: true,
        //   title1: "开启“九吨红人榜”评选活动",
        //   time1: "2020-10",
        //   isTrue1: true,
        //   url1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        //   url2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        // },
        // {
        //   id: 3,
        //   title: "赋比兴科技入选“两江新区2023年度软件和信息服务企业综合竞争力20强",
        //   time: "2020-10",
        //   isTrue1: false,
        //   isTrue2: true,
        //   title1: "九吨原创IP形象“吨总”诞生",
        //   time1: "2020-10",
        //   url1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        //   url2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        // },
        // {
        //   id: 4,
        //   title: "九吨全资子公司（重庆赋比兴科技有限公司）成立",
        //   time: "2020-10",
        //   isTrue1: true,
        //   title1: "2022年9月 九吨新加坡公司（NINETON PTE. LTD.）成立",
        //   time1: "2020-10",
        //   isTrue2: true,
        //   url1: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        //   url2: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-12-02/5a83e381aa79d794e5f20bfce0cef4b9.png",
        // }
      ],
      imageList: [
        // {
        //   url: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-06-07/tourism.jpg",
        //   id: 1,
        // },
        // {
        //   url: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-02-29/7a14cc1683e6accb636ac026071a17c7.jpg",
        //   id: 2,
        // },
        // {
        //   url: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2024-01-30/2701cdf2fdc93d6d3f3dc567760daf4f.jpg",
        //   id: 3,
        // },
        // {
        //   url: "https://nineton-zhongtai.obs.cn-southwest-2.myhuaweicloud.com/guan-wang/2023-10-31/170ccf4e56964fc6e7986196cc92c94f.jpg",
        //   id: 4,
        // },
        // {
        //   url: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/2019team_video_toutu.jpeg",
        //   id: 5,
        // },
      ],
      items: [
        {
          name: "1",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/tianqiyubao_logo.png",
          title: "天气预警",
          conent:
            "可精准定位至街道，预报数据详细及时准确的天气类APP；连续多次荣获各大网站和市场的用户喜欢的免费天气、天气预报应用；Appstore天气、天气预报分类常年位居前二！超过10,000,000+天气用户的信赖！",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_4.png",
        },
        {
          name: "2",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/kexiu_logo.png",
          title: "可秀",
          conent:
            "可秀是一款超好玩的视频特效APP，腾云驾雾、广场舞、蝴蝶特效等，深受友友们喜爱~",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_4.png",
        },
        {
          name: "3",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/tianqiyubao_logo.png",
          title: "天气预警",
          conent:
            "可精准定位至街道，预报数据详细及时准确的天气类APP；连续多次荣获各大网站和市场的用户喜欢的免费天气、天气预报应用；Appstore天气、天气预报分类常年位居前二！超过10,000,000+天气用户的信赖！",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_4.png",
        },
        {
          name: "4",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/kexiu_logo.png",
          title: "可秀",
          conent:
            "可秀是一款超好玩的视频特效APP，腾云驾雾、广场舞、蝴蝶特效等，深受友友们喜爱~",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_4.png",
        },
      ],
      setTimeIndex: null,
    };
  },
  computed: {
    scrollStyle() {
      return {
        animationPlayState: this.isPaused ? 'paused' : 'running',
      };
    },
  },
  mounted() {
    this.businessFun();
    this.BriefIntroductionFun();
    this.NewsFun();
    this.BenefitFun();
    this.JoinUsFun();
    this.ContactUsFun();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Array.from(entry.target.parentNode.children).indexOf(
              entry.target
            );
            this.isVisible = index;
            this.isVisible1 = index;
          }
        });
      },
      {
        threshold: 0.2, // 交叉比例达到50%时触发回调
      }
    );

    const moduleElement = document.querySelectorAll(".section_box");
    moduleElement.forEach((module) => {
      observer.observe(module);
    });
    this.SetTimeoutFun();
  },
  methods: {
    ContactUsFun(){
      ContactUs().then(res=>{
        console.log(res,"------res")
        let data = res.data;
        this.ContactUsTitle = data.title;
        this.ContactUsAddress = data.Address;
        this.ContactUsPhone = data.Phone;
        this.ContactUsEmail = data.Email;
      }).catch(err=>err);
    },
    JoinUsFun(){
      JoinUs().then(res=>{
        console.log(res,"------res")
        let data = res.data;
        this.JoinUsTitle = data.title;
        this.JoinUsList = data.JoinList;
      }).catch((err=>err))
    },
    BenefitFun(){
      Benefit().then(res=>{
        console.log(res,"------res")
        let data = res.data;
        this.BenefitTitle = data.title;
        this.contentList = data.contentList;
      }).catch(err=>err)
    },
    NewsFun(){
      News().then(res=>{
        console.log(res,"------res")
        let data = res.data;
        this.NwesTitle = data.title;
        this.scrollList = data.scrollList;
      }).catch(err=>err);
    },
    BriefIntroductionFun(){
      BriefIntroduction().then(res=>{
        let data = res.data;
        this.BriefIntroductionTitle = data.title;
        this.CompanyName = data.CompanyName;
        this.conent1 = data.conent1;
        this.conent2 = data.conent2;
        this.imageList = data.carouseList;
      }).catch(err=>err);
    },
    businessFun(){
      business().then(res=>{
        
        let data = res.data;
        this.businessTitle = data.title;
        this.items = data.list;
      }).catch(err=>err)
    },
    ready(){

    },
    SetTimeoutFun() {
      this.setTimeIndex = setInterval(() => {
        this.isVisible1 = 88;
        this.currentIndex = (this.currentIndex + 1) % this.items.length;
        setTimeout(() => {
          this.isVisible1 = 1;
        }, 1000);
      }, 5000);
    },
    pauseScrollFun() {
      this.isActive = false;
      clearInterval(this.setTimeIndex);
      this.setTimeIndex = null;
    },
    resumeScrollFun() {
      this.isActive = true;
      this.SetTimeoutFun();
    },
    pauseScroll() {
      this.isPaused = true;
    },
    // 鼠标划出时恢复滚动
    resumeScroll() {
      this.isPaused = false;
    },
    backgrFundColor(index) {
      if (index < 4) {
        if (index % 2 == 0) {
          return "#fff";
        } else {
          return "hsla(0, 0%, 85%, .1)";
        }
      } else {
        if (index % 2 == 0) {
          return "hsla(0, 0%, 85%, .1)";
        } else {
          return "#fff";
        }
      }
    },
  },
};
</script>
  
  <style scoped lang="less">
@import url("./index.css");
</style>