<template>
  <div class="main_box">
    <section id="section1" class="section_box">
      <div class="section4_box">
        <div class="section4_left">
          <div class="text_box">
            <h1 class="head_name">{{ businessTitle }}</h1>
            <div class="icon_origin"></div>
            <div :class="[`translate_left4`, { active: isVisible == 0 }]">
              <el-image
                class="img_box"
                :src="items[currentIndex].url1"
              ></el-image>
              <h3 class="head_h3">{{ items[currentIndex].title }}</h3>
              <div class="conent_text">
                {{ items[currentIndex].conent }}
              </div>
              <el-button class="btn" type="warning" plain>IOS</el-button>
            </div>
            <div class="transla_left">
              <el-button
                v-show="currentIndex"
                @click="prevSlide"
                class="prev_btn"
                type="warning"
                icon="el-icon-back"
                circle
              ></el-button>
              <div class="inages_num">
                {{ currentIndex + 1 }} / {{ items.length }}
              </div>
              <el-button
                @click="nextSlide"
                type="warning"
                class="prev_btn"
                icon="el-icon-right"
                circle
              ></el-button>
            </div>
          </div>
        </div>
        <div :class="[`section4_right1`, { active: isVisible == 0 }]">
          <el-image
            class="section4_right2_img"
            :src="items[currentIndex].url3"
          ></el-image>
          <el-image
            class="section4_right1_img"
            :src="items[currentIndex].url2"
          ></el-image>
          <el-image
            class="section4_right3_img"
            :src="items[currentIndex].url4"
          ></el-image>
        </div>
      </div>
    </section>
    <section id="section2" class="section_box">
      <div :class="[`section2_box`, { active: isVisible == 1 }]">
        <div class="section4_left2">
          <h1 class="head_name">{{ CooperationTitle }}</h1>
          <div class="icon_origin"></div>
          <div class="translate_left1">
            <h1 class="translate_left_h1">{{ CooperationApp }}</h1>
            <div class="translate_left_name">{{ CooperationName }}</div>
            <div class="translate_left_mail">{{ CooperationEmail }}</div>
          </div>
        </div>
        <div class="section4_right2">
          <el-image class="section4_right4_img" :src="url5"></el-image>
        </div>
      </div>
    </section>
    <section id="section3" class="section_box">
      <div class="section1_box1">
        <div class="section1_left6">
          <h1 class="head_name">SDK业务</h1>
          <div class="icon_origin"></div>
          <div class="translate_left6">
            <div
              class="sdk-wrapper"
              v-for="(item, index) in sdkList"
              :key="index"
            >
              <div class="sdk-card">
                <div class="sdk-version">{{ item.version }}</div>
                <div class="sdk-info">
                  <div
                    class="info-item"
                    v-for="(info, i) in item.info"
                    :key="i"
                  >
                    <span class="label">{{ info.label }}：</span>
                    <span class="value">{{ info.value }}</span>
                  </div>
                </div>
              </div>
              <div class="sdk-links">
                <a
                  v-for="(link, i) in item.links"
                  :key="i"
                  :href="link.url1"
                  target="_blank"
                  >{{ link.name }} →</a
                >
              </div>
            </div>
            <div class="sdk-wrapper"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { business, BusinessCooperation, SDKBusiness } from "@/https/api.js";
export default {
  data() {
    return {
      isVisible: 0,
      businessTitle: "",
      CooperationTitle: "",
      CooperationName: "",
      CooperationEmail: "",
      CooperationApp: "",
      url5: "",
      SDKBusinessTiqle: "",
      currentIndex: 0,
      sdkList: [],
      items: [
        {
          name: "1",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/tianqiyubao_logo.png",
          title: "天气预警",
          conent:
            "可精准定位至街道，预报数据详细及时准确的天气类APP；连续多次荣获各大网站和市场的用户喜欢的免费天气、天气预报应用；Appstore天气、天气预报分类常年位居前二！超过10,000,000+天气用户的信赖！",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_4.png",
        },
        {
          name: "2",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/kexiu_logo.png",
          title: "可秀",
          conent:
            "可秀是一款超好玩的视频特效APP，腾云驾雾、广场舞、蝴蝶特效等，深受友友们喜爱~",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_4.png",
        },
        {
          name: "3",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/tianqiyubao_logo.png",
          title: "天气预警",
          conent:
            "可精准定位至街道，预报数据详细及时准确的天气类APP；连续多次荣获各大网站和市场的用户喜欢的免费天气、天气预报应用；Appstore天气、天气预报分类常年位居前二！超过10,000,000+天气用户的信赖！",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_tianqiyubao_4.png",
        },
        {
          name: "2",
          url1: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/kexiu_logo.png",
          title: "可秀",
          conent:
            "可秀是一款超好玩的视频特效APP，腾云驾雾、广场舞、蝴蝶特效等，深受友友们喜爱~",
          url2: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_3.png",
          url3: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_2.png",
          url4: "https://zhongtai-admin.oss-cn-hangzhou.aliyuncs.com/guanwang/yewu_kexiu_4.png",
        },
      ],
    };
  },
  mounted() {
    this.businessFun();
    this.BusinessCooperationFun();
    this.SDKBusinessFun();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = Array.from(entry.target.parentNode.children).indexOf(
              entry.target
            );
            this.isVisible = index;
          }
        });
      },
      {
        threshold: 0.2, // 交叉比例达到50%时触发回调
      }
    );
    const moduleElement = document.querySelectorAll(".section_box");
    moduleElement.forEach((module) => {
      observer.observe(module);
    });
  },
  methods: {
    SDKBusinessFun() {
      SDKBusiness().then((res) => {
        console.log(res, "res");
        let data = res.data;
        this.SDKBusinessTiqle = data.title;
        this.sdkList = data.sdkList;
      }).catch((err) => {
        return err
      })
    },
    BusinessCooperationFun() {
      BusinessCooperation().then((res) => {
        let data = res.data;
        this.CooperationTitle = data.title;
        this.CooperationName = data.Name;
        this.CooperationEmail = data.Email;
        this.CooperationApp = data.AppAdvertisement;
        this.url5 = data.url;
      }).catch((err) => {
        return err;
      });
    },
    businessFun() {
      business()
        .then((res) => {
          let data = res.data;
          this.businessTitle = data.title;
          this.items = data.list;
        })
        .catch((err) => {
          return err;
        });
    },
    nextSlide() {
      this.isVisible = 9;
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
      setTimeout(() => {
        this.isVisible = 0;
      }, 1000);
    },
    prevSlide() {
      this.isVisible = 9;
      this.currentIndex = this.currentIndex - 1;
      setTimeout(() => {
        this.isVisible = 0;
      }, 1000);
    },
  },
};
</script>
<style scoped lang="less">
@import url("./index.css");
/* 全局样式 */
</style>